<template>
  <v-row class="row--35" align="center">
    <v-col lg="6" md="6" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/1.png"
          alt="About Images"
        />
      </div>
    </v-col>
    <v-col lg="6" md="6" cols="12">
      <div class="about-inner inner">
        <div class="section-title text-left">
          <h2 class="heading-title">Despre noi</h2>
          <p>
            Suntem o echipă tânără cu multă energie și mult drag de sport, în
            special pentru sportul care ne dezvoltă atât de armonios, înotul.
            Bats of Transylvania a luat naștere din dorința de a aduce o
            schimbare în modul în care înotul este experimentat aici, în
            Cluj-Napoca, de la primul contact cu apa până la performanță.
          </p>
          <p>
            În viețile noastre, înotul a fost cel care ne-a învățat cum să
            gestionăm atât de multe situații stresante. Știm, poate sună ciudat,
            ,,Cum ar putea un sport să te învețe despre viață?”. Pentru noi așa
            a fost, de la faptul că te învață cum poți să-ți folosești
            respirația în avantajul tău, până la momentele de liniște cu tine
            însuți din timpul antrenamentelor, momente care ajută să te
            descoperi. Suntem veșnic recunoscători acestui sport și de aceea
            visul nostru este să împărtășim tot ceea ce știm cu oricine ne va
            călca pragul. This is our way to performance!
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
