<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="rn-testimonial-content">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in testimonialContent" :key="item.id">
            <v-card flat v-for="(item, i) in item.content" :key="i">
              <v-card-text>
                <div class="inner">
                  <p>
                    {{ item.description }}
                  </p>
                </div>
                <div class="author-info">
                  <h6>
                    <span>{{ item.name }}</span>
                  </h6>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!-- End .v-tabs-items -->
        <v-tabs v-model="tab" centered hide-slider center-active>
          <v-tab v-for="item in testimonialContent" :key="item.id">
            <div v-for="(item, i) in item.content" :key="i" class="thumb">
              <div class="testimonial-initial">{{ item.name[0] }}</div>
              <!-- <img :src="item.src" alt="testimonial image" /> -->
            </div>
          </v-tab>
        </v-tabs>
        <!-- End .v-tabs -->
      </div>
      <!-- End .rn-testimonial-content -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      testimonialContent: [
        {
          id: 1,
          content: [
            {
              name: "Ilinca",
              description:
                "Când copilul tău merge cu tot dragul la antrenamente și nu-și dorește să mai plece, înseamnă ca am făcut cea mai buna alegere. Felicitări echipei Bats of Transylvania! 😊",
            },
          ],
        },
        {
          id: 2,
          content: [
            {
              name: "Hedi",
              description:
                "Nu cred că există în lume un antrenor de înot pentru copii mai blând, mai profesionist, cu inima deschisă și drag de copii ca Andrei Pleșa ❤️ face minuni cu fetița noastră de 6 ani. Suntem binecuvântați că l-am cunoscut.",
            },
          ],
        },
        {
          id: 3,
          content: [
            {
              name: "Ioana",
              description:
                "Andrei ! Nu am cuvinte ! Mulțumesc ca m-ai învățat să înot așa cum nu credeam ca voi putea vreodată , mulțumesc că ai avut răbdare cu mine și că m-ai învățat să iubesc apa! Baftă în tot ceea ce ți-ai propus ! BATS Of Transilvania is the best !!!",
            },
          ],
        },
        {
          id: 4,
          content: [
            {
              name: "Alina",
              description:
                "Cea mai bună echipă de instructori de înot, sunt foarte empatici și organizați.",
            },
          ],
        },
        {
          id: 5,
          content: [
            {
              name: "Alexandra",
              description:
                "Recomand atât pentru cei care vor să învețe tehnica inotului cât și pentru cei care vor să se perfecționeze. Recomand, extrem de empatici cu copiii!",
            },
          ],
        },
        {
          id: 6,
          content: [
            {
              name: "Amalia",
              description:
                "Foarte fericit e baietelul meu de orele de înot. Se vede progres după fiecare ședința, sunt foarte multumita de echipa Bats of Transylvania.",
            },
          ],
        },
        {
          id: 7,
          content: [
            {
              name: "Constantina",
              description:
                "Răbdare, empatie, eficientă! Recunoștință antrenorilor pentru implicare! 🏊🏼‍♀️",
            },
          ],
        },
        {
          id: 8,
          content: [
            {
              name: "Ovidiu",
              description:
                "Antrenori dedicați, plini de răbdare și entuziasm.",
            },
          ],
        },
        {
          id: 9,
          content: [
            {
              name: "Alina",
              description:
                "O echipă de profesioniști faini, empatici, veseli!",
            },
          ],
        },
        {
          id: 10,
          content: [
            {
              name: "Bianca",
              description:
                "Behind every fearless player there is a fearless coach who refused to let them be anything but the best they can be. And this is Andrei, fully dedicated to teaching us the art of swimming, based on his experience as a professional swimmer. Hats off to YOU!",
            },
          ],
        },
        {
          id: 10,
          content: [
            {
              name: "Catalina",
              description:
                "Best trainers ever, mixing both technique and friendly environment for learning. My daughter is so excited about every class with them, and has been doing it now for almost 2.5years now.",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.testimonial-initial {
  border-radius: 8px;
  background: white;
  height: 40px;
  width: 40px;
  padding-top: 10px;
}
</style>
