<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="/">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: "users",
          title: "Unitate",
          desc: ` Pentru noi comunicarea stă la baza acestei valori. Știm că unitatea se formează atunci când viziunea este clar transmisă. Bats of Transylvania este locul unde împreună, ca o echipă, formăm caractere, nu doar sportivi.`,
        },
        {
          icon: "trending-up",
          title: "Progres",
          desc: ` Ne place atunci când facem ceva să-l facem bine, dar asta nu înseamnă că fericirea noastră se bazează doar pe rezultate. La Bats of Transylvania ne concentrăm pe progresul continuu în care călătoria fiecăruia este cea mai importantă.`,
        },
        {
          icon: "heart",
          title: "Longevitate",
          desc: `Viața nu este un sprint, este un maraton. În prezent totul este o goană după rezultate sau lucruri materiale, iar noi venim și încetinim timpul. Viziunea Bats of Transylvania este ca orice persoană antrenată de noi să trăiască 100+ ani, totul prin trăirea momentului prezent.`,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
