<template>
  <ul
    class="social-share social-style--2 color-black d-flex justify-content-start liststyle"
  >
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.url" target="_blank"
        ><i class="fab" :class="social.icon"></i
      ></a>
    </li>
  </ul>
</template>
<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/Bats.of.transylvania",
          },
          {
            icon: "fa-instagram",
            url: "https://www.instagram.com/bats.of.transylvania_swimming",
          },
          {
            icon: "fa-tiktok",
            url: "https://www.tiktok.com/@batsoftransylvania?_t=8c276J2qKmB&_r=1",
          },
        ],
      };
    },
  };
</script>
