<template>
  <v-row class="mt--30">
    <!-- Start Single Team Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="team in teamContent"
      :key="team.id"
    >
      <div class="team-static">
        <div class="thumbnail">
          <img :src="team.src" alt="Team Images" />
        </div>
        <div class="inner">
          <div class="content">
            <h4 class="title">{{ team.title }}</h4>
            <p class="designation">{{ team.designation }}</p>
          </div>

          <ul class="social-icon social-team liststyle d-flex">
            <li v-for="(social, i) in team.socialList" :key="i">
              <a :href="social.url" target="_blank">
                <i class="fab" :class="social.icon"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </v-col>
    <!-- End Single Team Area  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      teamContent: [
        {
          id: 1,
          src: require("../../assets/images/team/2.png"),
          title: "David Szabo",
          designation: "Swimming Coach",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/szabo.david.3192",
            },
            {
              icon: "fa-instagram",
              url: "https://instagram.com/cuvant?igshid=ZDc4ODBmNjlmNQ==",
            },
          ],
        },
        {
          id: 2,
          src: require("../../assets/images/team/1.png"),
          title: "Andrei Pleșa",
          designation: "Head Coach",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/andrei.plesa.712",
            },
            {
              icon: "fa-instagram",
              url: "https://instagram.com/andrei.plesa.pt?igshid=MmIzYWVlNDQ5Yg==",
            },
          ],
        },
        {
          id: 3,
          src: require("../../assets/images/team/3.png"),
          title: "Sergiu Mureșan",
          designation: "Swimming & Skating Coach",
          socialList: [
            {
              icon: "fa-facebook-f",
              url: "https://www.facebook.com/sergiu.muresan.106",
            },
            {
              icon: "fa-instagram",
              url: "https://instagram.com/sergiumuresan1?igshid=MmIzYWVlNDQ5Yg==",
            },
          ],
        },
      ],
    };
  },
};
</script>
