<template>
  <v-row>
    <v-col
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="standard-service">
        <div class="thumbnai">
          <img :src="service.src" alt="Corporate Images" />
        </div>
        <div class="content">
          <h3>
            {{ service.title }}
          </h3>
          <p>
            {{ service.desc }}
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/service/2.png"),
            title: "Inițiere în înot",
            desc: `Inițiem pe oricine de orice vârstă în tainele înotului.`,
          },
          {
            src: require("../../assets/images/service/3.png"),
            title: "Frică de apă?",
            desc: `Te învătăm noi cum să devi prietenul ei.`,
          },
          {
            src: require("../../assets/images/service/4.png"),
            title: "Performanță",
            desc: `Ești pregătit să experimentezi înotul la următorul nivel? Noi suntem!`,
          },
          {
            src: require("../../assets/images/service/5.png"),
            title: "Înot pentru viitoare mămici",
            desc: `Relaxează presiunile sarcinii prin sesiuni de înot în siguranță pentru tine și copilul tău.`,
          },
          {
            src: require("../../assets/images/service/6.png"),
            title: "Sănătate pentru Adulți",
            desc: `Vrei să te menții sănătos iar alt tip de miscare îți provoacă neplăceri? Te așteptăm cu drag în apă.`,
          },
          {
            src: require("../../assets/images/service/7.png"),
            title: "Înot cu nevoi speciale",
            desc: `Afecțiuni locomotorii sau recomandări de la medici? Ne dorim să fim lângă tine sau copilul tău pentru o viață mai sănătoasă.`,
          },
        ],
      };
    },
  };
</script>
