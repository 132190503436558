<template>
  <v-row>
    <!-- Start Blog Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in blogContent"
      :key="i"
    >
      <div class="blog blog-style--1">
        <div class="thumbnail">
          <router-link to="/">
            <img class="w-100" :src="item.src" alt="Blog Images" />
          </router-link>
        </div>
        <div class="content">
          <h4 class="heading-title">
            <router-link to="/">{{ item.title }}</router-link>
          </h4>
          <p class="blogtype">{{ item.meta }}</p>    
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        blogContent: [
          {
            src: require("../../assets/images/service/2.png"),
            title: "Inițiere în înot",
            meta: `Inițiem pe oricine de orice vârstă în tainele înotului.`,
          },
          {
            src: require("../../assets/images/service/3.png"),
            title: "Frică de apă?",
            meta: `Te învătăm noi cum să devi prietenul ei.`,
          },
          {
            src: require("../../assets/images/service/4.png"),
            title: "Performanță",
            meta: `Ești pregătit să experimentezi înotul la următorul nivel? Noi suntem!`,
          },
          {
            src: require("../../assets/images/service/5.png"),
            title: "Înot pentru viitoare mămici",
            meta: `Relaxează presiunile sarcinii prin sesiuni de înot în siguranță pentru tine și copilul tău.`,
          },
          {
            src: require("../../assets/images/service/6.png"),
            title: "Sănătate pentru Adulți",
            meta: `Vrei să te menții sănătos iar alt tip de miscare îți provoacă neplăceri? Te așteptăm cu drag în apă.`,
          },
          {
            src: require("../../assets/images/service/7.png"),
            title: "Înot cu nevoi speciale",
            meta: `Afecțiuni locomotorii sau recomandări de la medici? Ne dorim să fim lângă tine sau copilul tău pentru o viață mai sănătoasă.`,
          },
        ],
      };
    },
  };
</script>
