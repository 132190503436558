<template>
  <div class="active-dark">
    <Header />
    <div class="rn-slider-area poss_relative" id="home">
      <div
        class="slide personal-portfolio-slider slider-style-3 slider-paralax bg_image bg_image--25 d-flex align-center"
      >
        <BannerThree>
          <h2 slot="designation">
            Împreună ne Dezvoltăm, Creștem și Învățăm prin Înot
          </h2>
          <div slot="heading-title" style="height: 40px">
            <vue-typed-js
              :typeSpeed="150"
              :showCursor="false"
              :strings="['Unitate', 'Progres', 'Longevitate']"
              :loop="true"
            >
              <h1 class="heading-title typing"></h1>
            </vue-typed-js>
          </div>
        </BannerThree>
      </div>
    </div>

    <div class="section about-area rn-section-gap bg_color--5" id="about">
      <div class="about-wrapper">
        <div class="container">
          <AboutTwo>
            <img
              slot="thum-img"
              class="w-100"
              src="../assets/images/about/about-8.jpg"
              alt="About Images"
            />
          </AboutTwo>
        </div>
      </div>
    </div>

    <div
      class="section service-area rn-service-area rn-section-gap bg_color--1"
      id="values"
    >
      <div class="container">
        <v-row class="mb-4">
          <v-col lg="12">
            <div
              class="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0"
            >
              <h2 class="heading-title">Valorile Noastre</h2>
              <p>
                Zilnic ne orientăm activitatea după 3 piloni principali care ne
                arată mereu ce este cu adevărat important în orice decizie luăm.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </div>
    </div>

    <div
      class="section rn-portfolio-area rn-section-gap bg_color--5"
      id="services"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title section-title--2 text-center mb--20">
              <h2 class="heading-title">Ce servicii oferim</h2>
              <p>
                Abordarea nostră din timpul cursurilor este ghidată după spusele
                unuia dintre idolii noștrii Bruce Lee, ”Be as water, my
                friend!”. Scopul nostru final este ca oricine ajunge la
                cursurile nostre să își creeze o relație cu apa și de la ea să
                învețe proprietățile incredibile ale acestui element.
              </p>
            </div>
          </v-col>
        </v-row>
        <Blog />
      </v-container>
    </div>

    <div class="section rn-blog-area rn-section-gap bg_color--1" id="team">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="6" md="12">
            <div class="section-title text-left">
              <h2>Echipă</h2>
            </div>
          </v-col>
        </v-row>
        <TeamTwo />
      </v-container>
    </div>

    <div class="section" style="position: relative">
      <img
        style="height: 100%; width: 100%; object-fit: contain"
        src="../assets/images/bg/skate.png"
      />
      <v-btn
        @click="window.open('tel:+40742597564')"
        color="accent"
        large
        style="position: absolute; top: 50%; left: 10%"
        >Suna acum</v-btn
      >
    </div>

    <div
      class="section rn-testimonial-area rn-section-gap bg_color--5"
      id="testimonial"
    >
      <v-container>
        <v-row class="mb-6">
          <v-col lg="12">
            <div class="section-title section-title--2 text-center mb--20">
              <h2 class="heading-title">Recomandări</h2>
              <p>Ce spun sportivii, parinții și clienții despre noi?</p>
            </div>
          </v-col>
        </v-row>
        <v-container>
          <Testimonial />
        </v-container>
      </v-container>
    </div>

    <div
      class="section rn-contact-area rn-section-gap bg_color--1"
      id="contact"
    >
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../assets/images/about/11.png"
              alt="contact"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header/Header";
import BannerThree from "../components/slider/BannerThree";
import AboutTwo from "../components/about/AboutTwo";
import ServiceThree from "../components/service/ServiceThree";
import ServiceFive from "../components/service/ServiceFive";
import TeamTwo from "../components/team/TeamTwo";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/FooterTwo";
import Testimonial from "../components/testimonial/Testimonial";
import Blog from "../components/blog/Blog";

export default {
  components: {
    Header,
    BannerThree,
    AboutTwo,
    ServiceThree,
    ServiceFive,
    TeamTwo,
    Contact,
    Footer,
    Testimonial,
    Blog
  },
  data() {
    return {
      window: window,
    };
  },
};
</script>
